import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Gap from "../componsts/Gap";
import NavBar from "../componsts/NavBar"

const Home = () => {
    return (
        <>
            <NavBar />

            <Container maxWidth="md">
                <div style={{ height: '32px' }} />
                <Typography gutterBottom variant="body1" mt={2} sx={{ textIndent: '2em' }} >
                    菜通宝专为餐饮行业研发设计的一款轻量级B2B货单管理系统。深入调研群体饭店、食堂等消费终端、
                    菜市商户商贩和配送中间商，以及探究其订单、发货单和验货单流转规律，从而量身打造的一套系统。
                    界面清晰，操作简捷，数据严谨，免费使用，是一款不可缺少的好工具。
                </Typography>
                <Gap height={32} />
                <Typography variant="h6">使用方式</Typography>
                <Typography variant="subtitle2">1、微信扫一扫</Typography>
                <Stack direction="column" alignItems="center">
                    <img src="/wxmini_qrcode_thumb.jpg" alt="wx_qrcode" style={{ width: '192px', height: '192px' }} />
                </Stack>
                <Gap height={16} />
                <Typography variant="subtitle2">2、微信搜索小程序：菜通宝</Typography>

                <Gap height={68} />
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" alignItems="center" >
                            <Typography variant="body1">
                                功能模块齐全，直击需求要害。
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" justifyContent="center" >
                            <img src="/mini_home.jpg" alt="mini_home" style={{ width: '192px', height: '416px' }} />
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" alignItems="center" >
                            <Typography variant="body1">
                                商行丰富，商品齐全。
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" justifyContent="center" >
                            <img src="/mini_goods.jpg" alt="mini_goods" style={{ width: '192px', height: '416px' }} />
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" alignItems="center" >
                            <Typography variant="body1">
                                首先选好供货日期与供货商，再选择商品即可快速给供货商下单。
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" justifyContent="center" >
                            <img src="/mini_re.jpg" alt="mini_re" style={{ width: '192px', height: '416px' }} />
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" alignItems="center" >
                            <Typography variant="body1">
                                货单界面清晰，数据整齐严谨。
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" justifyContent="center" >
                            <img src="/mini_order.jpg" alt="mini_order" style={{ width: '192px', height: '416px' }} />
                        </Stack>
                    </Grid>
                </Grid>
                <Gap height={32} />
            </Container>
        </>
    )
}

export default Home;