import { Stack, Typography } from "@mui/material";
import React from "react";
import BodyContainer from "../../componsts/BodyContainer";
import HelpNav from "./componsts/HelpNav";

const Inspection = props => {
    return (<>
        <HelpNav />
        <BodyContainer>
            <Stack spacing={1}>
                <Typography  mt={2} variant="body1" sx={{ textIndent: '2em' }} >
                    在首页上边点击’验货‘，即进入收到的发货单列表页面；然后选择需要验货的项点击查看发货单详情，确认无误后点击底部‘接收’按钮完成验货。
                </Typography>
                <img src="/mini_insp1.jpg" alt="mini_insp1" width={260} height={563} />
                <img src="/mini_insp2.jpg" alt="mini_insp2" width={260} height={563} />
                <img src="/mini_insp3.jpg" alt="mini_insp3" width={260} height={563} />
            </Stack>
        </BodyContainer>
    </>)
}

export default Inspection