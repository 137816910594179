import { AppBar, Avatar, Box, Button, Container, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavBar = props => {
  const { title = '菜通宝' } = props
  const nav = useNavigate()
  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar>
        <Container maxWidth="md">
          <Stack direction="row" spacing={2}  >
            <Avatar src="/logo36.png" alt="logo" sx={{ width: { xs: 28, md: 30 }, height: { xs: 28, md: 30 } }} />
            <Typography variant="h5" component="a" href="/" sx={{
              fontFamily: 'monospace',
              color: 'inherit',
              textDecoration: 'none',
            }}>
              {title}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" onClick={() => {
              nav('/help')
            }}>帮助</Button>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar