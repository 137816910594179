import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import BottomBar from "./componsts/BottomBar";
import Home from './pages/home'
import Help from './pages/help/index'
import RelationBind from "./pages/help/Bind"
import PlaceOrder from './pages/help/PlaceOrder'
import MyOrder from './pages/help/MyOrder'
import Delivery from './pages/help/Delivery'
import Inspection from './pages/help/Inspection'

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="help" element={<Help />} >

        </Route>
        <Route path="help/bind" element={<RelationBind />} />
        <Route path="help/order" element={<PlaceOrder />} />
        <Route path="help/myOrder" element={<MyOrder />} />
        <Route path="help/delivery" element={<Delivery />} />
        <Route path="help/inspection" element={<Inspection />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <BottomBar />
    </BrowserRouter>
  );
}

export default App;
