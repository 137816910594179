import { Stack, Typography } from "@mui/material";
import React from "react";
import BodyContainer from "../../componsts/BodyContainer";
import HelpNav from "./componsts/HelpNav";

const MyOrder = props => {
    return (<>
        <HelpNav />
        <BodyContainer>
            <Stack spacing={1}>
            <Typography mt={2} variant="h6" >
                    查看客户下的订单
                </Typography>
                <Typography variant="body1" sx={{ textIndent: '2em' }} >
                    在首页上边点击’我的订单‘进入订单页面；‘总览’为所有订单的数据合计，‘订单’则为客户的具体订单详情。
                </Typography>
                <img src="/mini_my_order1.jpg" alt="mini_my_order1" width={260} height={496}/>
                <img src="/mini_my_order2.jpg" alt="mini_my_order2" width={260} height={496}/>
                <img src="/mini_my_order3.jpg" alt="mini_my_order3" width={260} height={496}/>
            </Stack>
        </BodyContainer>
    </>)
}

export default MyOrder