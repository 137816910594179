import { Container, Divider, Link, Stack, Typography } from "@mui/material";
import React from "react";
import Gap from './Gap'

const BottomBar = () => {

    const mStyle = {
        fontSize: 13,
        color: '#999'
    }
    return (
        <>
            <Gap height={20} />
            <Divider />
            <Container maxWidth="md">
                <Gap height={32} />
                <Stack direction="row" alignItems="center" flexWrap="wrap">
                    <Typography noWrap variant="subtitle2" sx={mStyle}>微信：xiaoxiong2466</Typography>
                    <div style={{ width: '16px' }} />
                    <Link variant="subtitle2" sx={mStyle} href="mailto:1120348592@qq.com" noWrap underline="none">邮箱：1120348592@qq.com</Link>
                </Stack>
                <Gap height={12} />
                <Stack direction="row" alignItems="center" flexWrap="wrap">
                    <Link sx={mStyle} href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43100202000378" underline="none" target="_blank" rel="noopener">
                        <Stack direction="row" alignItems="center">
                            <img src="/logo_gongan.png" width={18} height={18} alt="logo_gongan" />
                            <Typography variant="body1" sx={mStyle}>
                                湘公网安备43100202000378号
                            </Typography>
                        </Stack>
                    </Link>
                    <div style={{ width: '16px' }} />
                    <Link target="_blank" sx={mStyle} rel="noopener" underline="none" noWrap variant="subtitle2" href="https://beian.miit.gov.cn/#/Integrated/index">
                        湘ICP备2022007274号
                    </Link>
                    <div style={{ width: '16px' }} />
                    <Link noWrap variant="subtitle2" sx={mStyle} href="https://www.xiongxstudio.com" target="_blank" rel="noopener" underline="none">©2022 雄熊工作室</Link>
                </Stack>
                <Gap height={32} />
            </Container>
        </>
    )
}

export default BottomBar