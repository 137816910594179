import { Stack, Typography } from "@mui/material";
import React from "react";
import BodyContainer from "../../componsts/BodyContainer";
import Gap from "../../componsts/Gap";
import HelpNav from "./componsts/HelpNav";

const Delivery = props => {
    return (
        <>
            <HelpNav />
            <BodyContainer>
                <Stack spacing={1}>
                    <Typography mt={2} variant="h6" >
                        1、根据订单快速生成发货单
                    </Typography>
                    <Typography variant="body1" sx={{ textIndent: '2em' }} >
                        在'我的订单'中订单详情，点击客户栏右边‘生成发货单’按钮，这时将根据订单自动写入供货日期、客户信息、商品以及订单中商品数量；如果
                        商品有发送的历史将自动补充历史最近价格；编辑完成且确认无误后点击底部‘发送’按钮，将发货单发送给客户（消费终端）。
                    </Typography>
                    <img src="/mini_delivery1.jpg" alt="mini_delivery1" width={260} height={497} />
                    <img src="/mini_delivery2.jpg" alt="mini_delivery2" width={260} height={497} />
                    <Gap height={24} />
                    <Typography mt={2} variant="h6" >
                        2、无订单创建发货单
                    </Typography>
                    <Typography variant="body1" sx={{ textIndent: '2em' }} >
                        点击首页右上角‘发货单’，进入发货单列表页面；点击底部‘创建发货单’，再选择‘直接创建发货单’，即进入创建发货单页面；
                        选择客户、供货日期、商品以及填写数量和价格后，确认无误后点击底部‘发送’按钮，将发货单发送给客户（消费终端）。
                    </Typography>
                    <img src="/mini_delivery3.jpg" alt="mini_delivery3" width={260} height={497} />
                    <img src="/mini_delivery4.jpg" alt="mini_delivery4" width={260} height={497} />
                    <img src="/mini_delivery5.jpg" alt="mini_delivery5" width={260} height={497} />
                    <img src="/mini_delivery6.jpg" alt="mini_delivery6" width={260} height={497} />
                    <img src="/mini_delivery7.jpg" alt="mini_delivery7" width={260} height={497} />
                </Stack>
            </BodyContainer>
        </>
    )
}

export default Delivery;