import { AppBar, Container, IconButton, Toolbar, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";


const HelpNav = props => {
    const nav = useNavigate()
    const loca = useLocation()
    return (
        <AppBar position="sticky" color="inherit"  >
            <Toolbar>
                <Container maxWidth="md" sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                    <IconButton onClick={() => nav('../help')}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography noWrap>
                        {loca?.state?.title}
                    </Typography>
                </Container>
            </Toolbar>
        </AppBar>
    )
}

export default HelpNav