import { Stack, Typography } from "@mui/material";
import React from "react";
import BodyContainer from "../../componsts/BodyContainer.js";
import Gap from "../../componsts/Gap.js";
import HelpNav from './componsts/HelpNav.js'

const RelationBind = props => {

    return (
        <>
            <HelpNav />
            <BodyContainer>
                <Stack spacing={1}>
                    <Typography mt={2} variant="h6" >
                        1、邀请客户
                    </Typography>
                    <Typography variant="body1" sx={{ textIndent: '2em' }} >
                        在首页点击左上角’客户‘，即进入客户列表页面；然后点击下面‘邀请客户’按钮，将邀请消息分享到微信好友即可。
                        （微信好友点击该小程序消息进入菜通宝，完成注册步骤即可绑定客户关系）
                    </Typography>
                    <img src="/mini_merchant_home.jpg" alt="mini_merchant_home" width={260} height={497} />
                    <img src="/mini_comsumer_list.jpg" alt="mini_comsumer_list" width={260} height={497} />
                    <Gap height={24} />
                    <Typography mt={4} variant="h6" >
                        2、邀请供货商
                    </Typography>
                    <Typography variant="body1" sx={{ textIndent: '2em' }} >
                        在首页点击左上角’供货商‘，即进入供货商列表页面；然后点击下面‘邀请供货商’按钮，将邀请消息分享到微信好友即可。
                        （微信好友点击该小程序消息进入菜通宝，完成注册步骤即可绑定供货商关系）
                    </Typography>
                    <img src="/mini_client_home.jpg" alt="mini_client_home" width={260} height={566} />
                    <img src="/mini_supplier_list.jpg" alt="mini_supplier_list" width={260} height={565} />
                </Stack>
            </BodyContainer>
        </>
    )
}

export default RelationBind