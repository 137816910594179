import { Stack, Typography } from "@mui/material";
import React from "react";
import BodyContainer from "../../componsts/BodyContainer";
import Gap from "../../componsts/Gap";
import HelpNav from "./componsts/HelpNav";

const PlaceOrder = props => {
    return (<>
        <HelpNav />
        <BodyContainer>
            <Stack spacing={1}>
                <Typography mt={2} variant="h6" >
                    1、下订单
                </Typography>
                <Typography variant="body1" sx={{ textIndent: '2em' }} >
                    在首页点击右上角’下定单‘进入已下订单列表；然后点击下面‘新增订单’按钮，进入创建订单页面；
                    选择供货日期和供货商，再选择商品，为商品设置数量和填写附加信息，最后点击底部‘确定’按钮即可创建并将订单发送给供货商。
                    （如已为供货商设置常供模版，选择供货日期和供货商后，将自动把常供模版的商品列出，为此减去选择商品的步骤，使下订单更快速。）
                </Typography>
                <img src="/mini_place_order1.jpg" alt="mini_place_order1" width={260} height={564}/>
                <img src="/mini_place_order2.jpg" alt="mini_place_order2" width={260} height={564}/>
                <img src="/mini_place_order3.jpg" alt="mini_place_order3" width={260} height={564}/>
                <img src="/mini_place_order4.jpg" alt="mini_place_order4" width={260} height={564}/>
                <img src="/mini_place_order5.jpg" alt="mini_place_order5" width={260} height={564}/>
                <img src="/mini_place_order6.jpg" alt="mini_place_order6" width={260} height={564}/>
                <img src="/mini_place_order7.jpg" alt="mini_place_order7" width={260} height={564}/>
                <Gap height={24}/>
                <Typography  variant="h6" >
                    2、设置供货商常供（订单模版）
                </Typography>
                <Typography variant="body1" sx={{ textIndent: '2em' }} >
                    在首页上边点击’设置常供‘进入常供列表；然后点击底部‘设置供货商常供（下单模版）’按钮，进入创建订单模版页面；
                    选择供货商，再添加经常供售的商品，最后点击底部‘确定’按钮即可为供货商设置常供（订单模版）。
                </Typography>
                <img src="/mini_temp1.jpg" alt="mini_temp1" width={260} height={564}/>
                <img src="/mini_temp2.jpg" alt="mini_temp2" width={260} height={564}/>
                <img src="/mini_temp3.jpg" alt="mini_temp3" width={260} height={564}/>
                <img src="/mini_temp4.jpg" alt="mini_temp4" width={260} height={564}/>
                <img src="/mini_temp5.jpg" alt="mini_temp5" width={260} height={564}/>
            </Stack>
        </BodyContainer>
    </>)
}

export default PlaceOrder;