import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from "react";
import NavBar from '../../componsts/NavBar'
import { useNavigate } from "react-router-dom";
import BodyContainer from "../../componsts/BodyContainer";

const Help = props => {
    const nav = useNavigate()
    const data = [
        { title: '供货商、消费终端关系绑定', path: 'bind' },
        { title: '给供货商下订单', path: 'order' },
        { title: '供货商查看订单', path: 'myOrder' },
        { title: '供货商发送送货单', path: 'delivery' },
        { title: '根据发货单验货', path: 'inspection' },
        // { title: '查看验货单', path: '/help/bind' },
        // { title: '货单数据对比', path: '/help/bind' },
        // { title: '对账', path: '/help/bind' },
    ]
    return (
        <>
            <NavBar />
            <BodyContainer>
                <List >
                    {data.map((v, index) => (
                        <ListItem key={index} disablePadding component="div">
                            <ListItemButton divider onClick={() => nav(v.path, { state: { title: v.title } })}>
                                <ListItemText primary={v.title} />
                                <ChevronRightIcon fontSize="small" />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </BodyContainer>
        </>
    )
}

export default Help